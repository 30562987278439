import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { map, take } from "rxjs";
import { AuthService } from "src/services/auth.service";

export const TokenGuard:CanActivateFn = () => {
  const _authService = inject(AuthService)
  return _authService.isLogged.pipe(
    take(1),
    map( (isLogged) => {
      return isLogged
    })
  )
}
