import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/services/auth.service';
import { userRegister } from './interface';
import { MatDialog } from '@angular/material/dialog';
import { TermAndConditionComponent } from '../dialogs/term-and-condition/term-and-condition.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  public formRegister : FormGroup
  public hide = true; //button of password visibility
  public hide2 = true; //button of repeat_password visibility
  public type_document: any = ['J', 'G', 'E'];
  public allComplete: boolean = true
  
  constructor(
    private _builder: FormBuilder,
    private authService: AuthService,
    private _dialog: MatDialog,
  ){
    
    this.formRegister = this._builder.group({
      email: ['',Validators.compose([Validators.required, Validators.email])],
      type: ['', Validators.required],
      term: [false, Validators.requiredTrue],
      document: ['', Validators.required],
      password: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[\\d])(?=.*[.,*\\_\\-#$])[A-Za-z\\d.,*\\_\\-#$]{8,16}$')
      ])],
      repeat_password: ['', Validators.compose([
        Validators.required, 
        Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[\\d])(?=.*[.,*\\_\\-#$])[A-Za-z\\d.,*\\_\\-#$]{8,16}$')
      ])]
    },
    {
      validators: this.mustMatch('password','repeat_password')
    })

  }

  get email(){
    return this.formRegister.get('email') as FormControl
  }

  get type(){
    return this.formRegister.get('type') as FormControl
  }

  get document(){
    return this.formRegister.get('document') as FormControl
  }

  get password(){
    return this.formRegister.get('password') as FormControl
  }

  get repeat_password(){
    return this.formRegister.get('repeat_password') as FormControl
  }

  get f(){
    return this.formRegister.controls
  }

  // validate thats password is equal to password_repead
  mustMatch(password: string, conpassword: string){
    return (formGroup:FormGroup) => {
      const pass = formGroup.controls[password]
      const conpass = formGroup.controls[conpassword]

      if (conpass.errors && !conpass.errors['MustMatch']) {
        return
      }
      if (pass.value !== conpass.value) {
        conpass.setErrors({MustMatch: true})
      } else {
        conpass.setErrors(null)
      }
    }
  }

  // Register Commerce
  async onSubmit(value: userRegister){    
    const { email, type, document, password } = value
    this.authService.registerCommerce({ email, type, document, password }).subscribe()
  }

  termAndCondition(){
    // let dialogResult = this._dialog.open(TermAndConditionComponent, {
    //   data: true,
    //   height: '80vh',
    //   width: '600px',
    //   enterAnimationDuration:'500ms',
    //   exitAnimationDuration:'500ms',
    // });

    // dialogResult.afterClosed().subscribe((result) => {
    //   if (result == false || result == undefined) {
    //     this.formRegister.controls['term'].setValue(false)
    //   }else if (result == true){
    //     this.formRegister.controls['term'].setValue(true)
    //   }
    // });
    this.formRegister.controls['term'].setValue(true)
    const url = 'https://www.ruedalo.app/terminos-y-condiciones/'; // Aquí debes especificar el enlace al que deseas redirigir
    window.open(url, '_blank');

  }

}
