<div class="main-content w-100 h-100">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center" style="height: 100vh;">
          <div class="col-10 col-sm-4">
            <div class="card p-2">
              <div align="center" class="pt-2">
                <img src="assets/images/logo_ruedalo.png" class="img-fluid w-25" alt="">
              </div>
              <div class="card-body">
                <h4 class="card-title" align="center"><strong>Inicio de Sesion</strong></h4>
                <form [formGroup]="formUsername">
                  <div>
                    <mat-form-field>
                      <mat-label>Email:</mat-label>
                      <input matInput placeholder="email" formControlName="email" type="text" required>
                      <mat-icon matSuffix> email </mat-icon>
                      <mat-error *ngIf="email.errors?.['email'] && email.touched"> Formato email invalido </mat-error>
                      <mat-error *ngIf="email.errors?.['required'] && email.touched">email requerido </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="mt-3">
                    <mat-form-field>
                      <mat-label>Password:</mat-label>
                      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
                      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                      <mat-error *ngIf="password.errors?.['pattern'] && password.touched"> Formato de Password Invalido</mat-error>
                      <mat-error *ngIf="password.errors?.['required']&& password.touched">Password requerido </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group" align="right">
                    <a routerLink="/recovery" >¿Olvidó su contraseña?</a>
                  </div>
                  <div class="form-group mt-3" align="center">
                    <button mat-raised-button color="primary" class="buttonplano" [disabled]="formUsername.invalid" (click)="onSubmit(formUsername.value)">
                      Ingresar
                    </button>
                  </div>
                  <div class="form-group mt-4" align="center">
                    ¿Aún no tiene cuenta? <a routerLink="/register">¡Registrate!</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  