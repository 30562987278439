import { Component, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { AlertService } from 'src/services/alert.service';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements AfterViewInit {

  formValidateEmail: FormGroup

  constructor(
    private _builder: FormBuilder,
    private _authService: AuthService,
    private _alertService: AlertService,
    private _router : Router
  ){
    

    this.formValidateEmail = this._builder.group({
      cod: ['',Validators.compose([Validators.required, Validators.minLength(4)])]
    })
  }

  async ngAfterViewInit() {
    this._authService.resendOTP(this._authService.readToken!).subscribe()
  }


  get validateEmail(){
    return this.formValidateEmail.get('cod') as FormControl
  }

  resendOTP(){
    this._authService.resendOTP(this._authService.readToken!).subscribe(resp => {
      resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
    })
  }

  async validateOTP(){
    this._authService.validateOTP(this._authService.readToken!,this.formValidateEmail.value.cod).subscribe( resp => {
      
      if (resp.status == true) {
        this._router.navigate(['/login'])
      }
    })
  }
}
