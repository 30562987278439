import { Component } from '@angular/core';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { userLogin } from './interface';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  formUsername : FormGroup
  hide: boolean = true
  constructor(
    private _builder: FormBuilder,
    private _authService: AuthService,
  ){
    localStorage.removeItem('token')
    localStorage.removeItem('commerce')

    this.formUsername = this._builder.group({
      email: ['',Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[\\d])(?=.*[.,*\\_\\-#$])[A-Za-z\\d.,*\\_\\-#$]{8,16}$')])]
    })

   this._authService.getTokenprueba().subscribe(resp => console.log(resp))
  }
  
  get email(){
    return this.formUsername.get('email') as FormControl
  }

  get password(){
    return this.formUsername.get('password') as FormControl
  }

  async onSubmit(from: userLogin){
    const { email, password } = from
    this._authService.logiIn({ email, password }).subscribe()
  }

}
