

<router-outlet>
    

<div class="w-100 h-100 d-flex justify-content-center align-items-center p-3 loader" 
style="
position: fixed;
z-index: 100;
background: #000;
opacity: 0.8;
"  *ngIf="isLoadin$ | async">
<mat-spinner></mat-spinner>
</div>
</router-outlet>