
    <mat-card class="h-100">
        <mat-card-header class="d-flex justify-content-center">
            <div>
                <h3><strong>Terminos y Condiciones</strong></h3>
            </div>
        </mat-card-header>

        <mat-card-content style="height: 80%;">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero doloribus aliquid id sint iste culpa laudantium obcaecati quasi laboriosam rem quia atque incidunt magni excepturi officia aspernatur, temporibus, quos doloremque.
        </mat-card-content>

        <mat-card-footer class="d-flex justify-content-center">
            <div class="col-10 col-md-6 d-flex justify-content-around">
                <div><button mat-raised-button color="primary" class="rounded-0" [mat-dialog-close]="true" cdkFocusInitial><mat-icon>done</mat-icon> Aceptar</button></div>
                <div><button mat-raised-button color="warn" class="rounded-0" [mat-dialog-close]="false" cdkFocusInitial><mat-icon>closed</mat-icon> Cancelar</button></div>
            </div>
        </mat-card-footer>
    </mat-card>
