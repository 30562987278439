<div class="main-content">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center" style="height: 100vh;">
          <div class="col-sm-6 col-md-4">
            <div class="card p-2">
              <div align="center" class="pt-2">
                <img src="assets/images/logo_ruedalo.png" class="img-fluid w-25" alt="">
              </div>
              <div class="card-body">
                <h4 class="card-title" align="center"><strong>Verifique su Correo</strong></h4>
                <form [formGroup]="formValidateEmail">
                  <div>
                    <mat-form-field>
                      <mat-label>Codigo:</mat-label>
                      <input matInput placeholder="Codigo" formControlName="cod" type="text" maxlength="4" required>
                      <mat-icon matSuffix> lock </mat-icon>
                      <mat-error *ngIf="validateEmail.errors?.['required'] && validateEmail.touched"> Codigo Requerido </mat-error>
                    </mat-form-field>
                  </div>
                
                  <div class="col-12 d-flex justify-content-around pt-3">
                   <div>
                    <button mat-raised-button color="primary" class="rounded-0" [disabled]="formValidateEmail.invalid" (click)="validateOTP()">
                        Validar
                    </button>
                   </div>

                    <div>
                        <button mat-raised-button color="warn" class="rounded-0" (click)="resendOTP()">
                            Reenviar
                        </button>
                    </div>
                  </div>

                  <div class="row pt-5 text-center">
                    <a class="nav-link" routerLink="/login">Volver a Iniciar Sesion</a>
                  </div>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  