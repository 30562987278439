export const environment = {
    production: false,
    limit: 5000,
    URLBack: 'https://backend.ruedalo.app/back-office',
    keyEncrypt: {
        encrypt: true,
        key: 'Repuestosya2023@-*'
    },
    matbox: {
        URL: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
        apikey: 'pk.eyJ1Ijoic3Rlcm5yZWl0ZXIiLCJhIjoiY2w5cnpjNnd6MDY4bjNucG8zdHIyOXFqcCJ9.a8uqRkEYD-jLgP5VV-gobw',
        limit: 5,


    }
}
