<div class="main-content align-items-center">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center px-3" style="height: 100vh;">
        <div class="col-sm-6 col-md-6 col-lg-4">
          <div class="card p-2">
            <div align="center" class="pt-2">
              <img src="assets/images/logo_ruedalo.png" class="img-fluid w-25" alt="">
            </div>
            <div class="card-body">
              <h4 class="card-title" align="center"><strong>Registro</strong></h4>
              <form [formGroup]="formRegister">
                <div>
                  <mat-form-field appearance="fill" class="field">
                    <mat-label>Email:</mat-label>
                    <input matInput placeholder="email" formControlName="email" type="text" required>
                    <mat-icon matSuffix> email </mat-icon>
                    <mat-error *ngIf="email.errors?.['email']"> Formato email invalido </mat-error>
                    <mat-error *ngIf="email.errors?.['required']">email requerido </mat-error>
                  </mat-form-field>
                </div>
  
                <div class="mt-3 d-flex">
                  <mat-form-field style="width:120px;">
                    <mat-label>Tipo</mat-label>
                    <mat-select required  formControlName="type">
                      <mat-option *ngFor="let type of type_document" value="{{type}}">{{type}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="type.errors?.['required']">Tipo de documento es requerido </mat-error>
                  </mat-form-field>
                  <mat-form-field style="width: 100%;">
                    <mat-label>Numero de documento:</mat-label>
                    <input matInput placeholder="Numero de Documento" formControlName="document" type="text"  maxlength="10" required>
                    <mat-error *ngIf="document.errors?.['required']">Numero de documento es requerido </mat-error>
                  </mat-form-field>                
                </div>
                <div class="mt-3">
                  <mat-form-field
                    class="field" 
                    matTooltip="Debe poseer al menos:
                                *1 Mayuscula.
                                *1 Minuscula.
                                *1 Numero.
                                *1 Caracter .,-_*$#"
                    matTooltipClass="tooltipcustom"
                    matTooltipPosition="after">
                    <mat-label>Contraseña:</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint  class="text-muted" *ngIf="password.errors?.['required'] == true && !!password.errors?.['pattern'] == false">1 Mayuscula, 1 Minuscula, 1 Numero y 1 Caracter especial</mat-hint>
                    <mat-error *ngIf="password.errors?.['required']">Password requerido </mat-error>
                    <mat-error *ngIf="password.errors?.['pattern']">Password invalido</mat-error>
                  </mat-form-field>
                </div>

                <div class="mt-3">
                  <mat-form-field class="field">
                    <mat-label>Repetir contraseña:</mat-label>
                    <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="repeat_password" required>
                    <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="repeat_password.errors?.['required']">Confirmar password es requerido</mat-error>
                    <mat-error *ngIf="repeat_password.errors?.['pattern']">Confirmar Password invalido</mat-error>
                    <mat-error *ngIf="f['repeat_password'].errors?.['MustMatch']">Los Password no coinciden</mat-error>
                  </mat-form-field>
                <div>

                  </div>
                </div>

                <div class="pb-3">
                  <mat-checkbox class="example-margin" formControlName="term" color="primary" (change)="termAndCondition()">Aceptar terminos y condiciones</mat-checkbox>
                </div>

                <div class="form-group mt-3" align="center">
                  <button mat-raised-button color="primary" class="buttonRegister" [disabled]="formRegister.invalid" (click)="onSubmit(formRegister.value)">
                    Registrar
                  </button>
                </div>

                <div class="form-group mt-4" align="center">
                  ¿Ya tienes una cuenta? <a routerLink="/login">¡Inicia sesion!</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  