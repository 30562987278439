import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'src/services/company.service';
import { address } from "../../dashboard/company/interface";

@Component({
  selector: 'app-edit-point',
  templateUrl: './edit-point.component.html',
  styleUrls: ['./edit-point.component.css']
})
export class EditPointComponent {
  public data: any;
  formData: FormGroup;

  constructor(
    private _builder: FormBuilder,
    private _companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public datadialog: any
  ){
    this.data = this.datadialog
    console.log(this.data);
    
    this.formData = this._builder.group({
      point_reference: ['', Validators.required ]
    })
  }

  UpdatePointReference(){
    console.log(this.formData.value.point_reference);
    const inseraddress: address ={
      id: this.data.id_BD,
      address: this.data.address,
      latitude: this.data.latitude,
      longitude: this.data.longitude,
      reference_point: this.formData.value.point_reference,
      principal: this.data.principal
    };
    this._companyService.updtaeAddress(inseraddress, localStorage.getItem('token')!).subscribe(data =>{
    })
  }
}
