<mat-card-header>
    <mat-card-title>Editar Punto de Referencia</mat-card-title>
</mat-card-header>
<mat-card-content>
    
</mat-card-content>

<div class="col-12 px-3">
    <mat-card-content>
    <form [formGroup]="formData">
        <div class="col col-md-12 pt-4">
           
            <div class="px-3">
                <mat-form-field class="w-100">
                    <mat-label>Punto de Referencia:</mat-label>
                    <input matInput placeholder="Point Reference" [(ngModel)]="data.reference_point" formControlName="point_reference" type="text" required>
                    <mat-error *ngIf="this.formData.get('point_reference')?.hasError('required')">Punto de Referencia Requerido</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row pt-4">
            <div class="col-12 d-flex justify-content-center">
                <div class="col-4 d-flex justify-content-between">

                    <button mat-flat-button color="primary" class="rounded-0 me-2 me-md-0" [disabled]="formData.invalid" (click)="UpdatePointReference()">
                        <mat-icon> save </mat-icon>
                        Actualizar
                    </button>
                </div>
            </div>
        </div>
        
    </form>
    </mat-card-content>
</div>
