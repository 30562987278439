import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { loginResponse, userLogin } from 'src/app/login/interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AlertService } from './alert.service';
import { responseOTP } from 'src/app/validate-email/interface';
import { Router } from '@angular/router';
import { forgotPassword, registerResponseI, userRegister } from 'src/app/register/interface';
import { CompanyService } from './company.service';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService()

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private URLBackend = environment.URLBack
  private loggeIn = new BehaviorSubject<boolean>(false)
  
  constructor(
    private _http: HttpClient,
    private _alertService: AlertService,
    private _router: Router,
  ) {
    this.checkTokenAuth()
  }

  get isLogged():Observable<boolean>{
    return this.loggeIn.asObservable()
  }

  /*
  **LogIn in System 
  */ 
  public logiIn(form: userLogin):Observable<loginResponse> {

    return this._http.post<loginResponse>(`${this.URLBackend}/login`,{
      "email": form.email,
      "password": form.password
    }).pipe(
      map((resp:loginResponse)=> {
        if (resp.data?.status == 'disabled') {
          this._alertService.alertSwal('error', 'Usuario Bloqueado, Por favor comuniquese con soporte.')
        }else{
          if(resp.status == true) { 
            this.saveToken(resp.data.token)
            this.loggeIn.next(true)
  
            if(resp.data.status == 'pending') {
              this._alertService.alertSwal('error', 'Se ha enviado un correo de verificacion, por favor revise.')
              this._router.navigate(['verify-email'])
  
            }else if(resp.data.status != 'pending') {
              resp.data.registered_name != null ? this._alertService.alertSwal('success', `Bienvenido ${resp.data.registered_name}`): this._alertService.alertSwal('success', `Bienvenido ${resp.data.email}`)
              localStorage.setItem('commerce', JSON.stringify(resp.data)!)
              this._router.navigate(['dashboard/home']);
  
            }
          } else{ 
            this._alertService.alertSwal('error', resp.message)
          }
        }
        return resp
      }),
      catchError(err => {
        console.log({err});
        
        this._alertService.alertSwal('error', err.error?.message ? err.error?.message : 'A ocurrido un error')
        return  throwError(err)
      })
    )
  }

  /*
  **LogOut user
  */ 
  public logOut(){
    this.loggeIn.next(false)
    localStorage.removeItem('token')
    localStorage.removeItem('commerce')
    this._router.navigate(['login'])
  }

  /*
  * verified token
  */ 

  checkTokenAuth(){
    const tokenUser = localStorage.getItem('token')
    const expire = helper.isTokenExpired(tokenUser)   
    expire ? this.logOut() : this.loggeIn.next(true) 
  }

  /*
  **Save TOKEN LocalStore
  */ 
  public saveToken(token: string){
    localStorage.setItem('token',token)
  }

  /*
  **Save data commerce localstore
  */ 
  public saveDataCommerce(data: any){
    localStorage.setItem('commerce',JSON.stringify(data))
  }

  /*
  **Read TOKEN LocalStore
  */ 
  get readToken(){
    return localStorage.getItem('token')
  }

  /*
  **Resend OTP
  */ 
  public resendOTP(token:string):Observable<responseOTP>{
    if (!token) throwError('Token no inexistente!') 
    
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' };
    return this._http.post<responseOTP>(`${ this.URLBackend }/verify-email`,{},{ headers }).pipe(
      map(resp => {
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', err.error.message)
        return  throwError(err)
      })
    )
  }

  /*
  **Validate OTP
  */ 
  public validateOTP(token:string, otp: string):Observable<responseOTP>{
    if (!token) throwError('Token no inexistente!') 
    
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' };
    return this._http.post<responseOTP>(`${ this.URLBackend }/verify-email`,{ otp },{ headers }).pipe(
      map(resp => {
        if(resp.status === true) { 
          this._alertService.alertSwal('success', resp.message) 
        } else {
          this._alertService.alertSwal('error', resp.message)
        }
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', err.error.message)
        return  throwError(err)
      })
    )
  }

  public getTokenprueba(){
    const headers = { 
      'client_id': 'odyIsImlhdCI6MT',
      'client_secret': 'oepe938jkdjkd',
      'content-type': 'application/json',
    }
    return this._http.post('http://10.56.0.1:8001/token',{},{ headers }).pipe(
      map( (resp:any) => {
        return resp
      }),
      catchError(err => {
        console.log(err);
        return  throwError(err)
      })
    )
  }

  /*
  **User Register
  */ 
  registerCommerce(form: userRegister):Observable<registerResponseI> {
    const headers = { 'Content-Type': 'application/json' };
    
    return this._http.post<registerResponseI>(`${this.URLBackend}/register_commerce`,{
      "rif": form.document,
      "rif_type":form.type,
      "email": form.email,
      "password": form.password
    },
    { headers }).pipe(
      map(resp => {

        if(resp.status == true) { 
          this.saveToken(resp.data.token)
          this._alertService.alertSwal('success', resp.message)
          this._router.navigate(['verify-email'])
        } else{ 
          this._alertService.alertSwal('error', resp.message)
        }

        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', err.error.message)
        return  throwError(err)
      })
    )
  }

  /*
  **User Forgot
  */

  forgotCommerce(data: forgotPassword){
    const headers = {  'Content-Type': 'application/json' };

    return this._http.post<registerResponseI>(`${this.URLBackend}/forgot-pass`,data,{ headers }).pipe(
      map(resp => {
        
        if(resp.status == true) { 
          this._alertService.alertSwal('success', resp.message)
        } else{ 
          this._alertService.alertSwal('error', resp.message)
        }

        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', err.error.message)
        return  throwError(err)
      })
    )
  }

}
