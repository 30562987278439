import { Component, OnInit } from '@angular/core';
import { SpinerService } from './spiner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public isLoadin$?: any

  constructor(
    private spinnerService: SpinerService
  ){}

  ngOnInit(): void {
    this.isLoadin$ =  this.spinnerService.isLoading$
  }

}
