import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { ResponseGetCommerce } from "src/app/dashboard/company/interface";
import { Observable, catchError, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { address, ResponseAddress ,ResponseAddressList } from "src/app/dashboard/company/interface";
import { responseGetlistEmployed, responseGetlistMP, responseInsertMP, responselistMP } from 'src/app/dashboard/product/interface';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private URLBackend: string = environment.URLBack
  private limite = environment.limit;
  public up_dataEmploye?: any
  public dataCommerce$ = new EventEmitter<any>()
  constructor(
    private _http: HttpClient,
    private _alertService: AlertService
  ) { }

  uploadPDF(form: any, token: string){
    const headers = { 'Authorization': `Bearer ${token}` }
    return this._http.post(`${ this.URLBackend }/upload_doc`,  form ,{ headers }).toPromise()
  }

  uploadKYC(form: any, token: string){
    const headers = { 'Authorization': `Bearer ${token}` }
    return this._http.post(`${ this.URLBackend }/update_commerce`,  form ,{ headers }).toPromise()
  }

  getDataCommerce(token: string):Observable<ResponseGetCommerce>{
    const headers = { 'Authorization': `Bearer ${token}` }
    return this._http.get<ResponseGetCommerce>(`${ this.URLBackend }/get_commerce`,{ headers }).pipe(
      map( (resp: ResponseGetCommerce) => {
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  // ADRRESS
  insertAddress(data:address, token: string){
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    return this._http.post<ResponseAddress>(`${ this.URLBackend }/register_address`,data,{ headers }).pipe(
      map( (resp: ResponseAddress) => {
        if (resp.status == true) {
          this._alertService.alertSwal('success', resp.message)
        }
        else {
          this._alertService.alertSwal('error', resp.message)
        }
  
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  listAddress(token: string){
    const data = { offset: 0 , limit: this.limite }
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    return this._http.post<ResponseAddressList>(`${ this.URLBackend }/list_address`,data,{ headers }).pipe(
      map( (resp: ResponseAddressList) => {
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  updtaeAddress(data:address, token: string){
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    return this._http.post<ResponseAddress>(`${ this.URLBackend }/update_address`,data,{ headers }).pipe(
      map( (resp: ResponseAddress) => {
        resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
        setTimeout(function(){
          window.location.reload();
        }, 3000);
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  deleteAddress(id: number, token: string){
    const headers = { 'Authorization': `Bearer ${token}` }
    return this._http.post<ResponseAddress>(`${ this.URLBackend }/delete_address`,{id: id},{ headers }).pipe(
      map( (resp: ResponseAddress) => {
        resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', err.error.message)
        return  throwError(err)
      })
    )
  }

  // METHOD PAYMENT
  getListMP(token: string):Observable<responselistMP>{
    const headers = { 'Authorization': `Bearer ${token}` }
    return this._http.get<responselistMP>(`${ this.URLBackend }/list_paymethod`,{ headers }).pipe(
      map( (resp: responselistMP) => {
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  insertMP(data:any, token: string){
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    return this._http.post<responseInsertMP>(`${ this.URLBackend }/register_paycommerce`,data,{ headers }).pipe(
      map( (resp: responseInsertMP) => {
        if (resp.status == true) {
          this._alertService.alertSwal('success', resp.message)
        }
        else {
          this._alertService.alertSwal('error', resp.message)
        }

        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  updateMP(data:any, token: string){
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    return this._http.post<responseInsertMP>(`${ this.URLBackend }/update_paycommerce`,data,{ headers }).pipe(
      map( (resp: responseInsertMP) => {
        resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', err.error.message)
        return  throwError(err)
      })
    )
  }

  ListMP(token: string){
    const data = {
      offset: 0,
      limit: this.limite
    }

    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }

    return this._http.post<responseGetlistMP>(`${ this.URLBackend }/list_paycommerce`,data,{ headers }).pipe(
      map( (resp: responseGetlistMP) => {
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  deleteMP(id: number, token: string){
    const headers = { 'Authorization': `Bearer ${token}` }
    return this._http.post<ResponseAddress>(`${ this.URLBackend }/delete_paycommerce`,{id: id},{ headers }).pipe(
      map( (resp: ResponseAddress) => {
        resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', err.error.message)
        return  throwError(err.error)
      })
    )
  }
  
// MWTHOD SHIPPING
  insertMS(data:any, token: string){
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    return this._http.post<responseInsertMP>(`${ this.URLBackend }/register_shipping`,data,{ headers }).pipe(
      map( (resp: responseInsertMP) => {
        if (resp.status == true) {
          this._alertService.alertSwal('success', resp.message)
        }
        else {
          this._alertService.alertSwal('error', resp.message)
        }

        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  updateMS(data:any, token: string){
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    return this._http.post<responseInsertMP>(`${ this.URLBackend }/update_shipping`,data,{ headers }).pipe(
      map( (resp: responseInsertMP) => {
        resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  ListMS(token: string){
    const data = {
      offset: 0,
      limit: this.limite
    }

    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }

    return this._http.post<responseGetlistMP>(`${ this.URLBackend }/list_shippings`,data,{ headers }).pipe(
      map( (resp: responseGetlistMP) => {
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
        return  throwError(err)
      })
    )
  }

  deleteMS(id: number, token: string){
    const headers = { 'Authorization': `Bearer ${token}` }
    return this._http.post<ResponseAddress>(`${ this.URLBackend }/delete_shipping`,{id: id},{ headers }).pipe(
      map( (resp: ResponseAddress) => {
        resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
        return resp
      }),
      catchError(err => {
        this._alertService.alertSwal('error', err.error.message)
        return  throwError(err)
      })
    )
  }
// EMPLOYED
insertEmployed(data:any, token: string){
  const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
  return this._http.post<responseInsertMP>(`${ this.URLBackend }/register_employee`,data,{ headers }).pipe(
    map( (resp: responseInsertMP) => {
      if (resp.status == true) {
        this._alertService.alertSwal('success', resp.message)
      }
      else {
        this._alertService.alertSwal('error', resp.message)
      }

      return resp
    }),
    catchError(err => {
      this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
      return  throwError(err)
    })
  )
}

updateEmployed(data:any, token: string){
  const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
  return this._http.post<responseInsertMP>(`${ this.URLBackend }/update_employee`,data,{ headers }).pipe(
    map( (resp: responseInsertMP) => {
      resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
      return resp
    }),
    catchError(err => {
      this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
      return  throwError(err)
    })
  )
}

ListEmployed(token: string){
  const data = {
    offset: 0,
    limit: this.limite
  }

  const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }

  return this._http.post<responseGetlistEmployed>(`${ this.URLBackend }/list_employee`,data,{ headers }).pipe(
    map( (resp: responseGetlistEmployed) => {
      return resp
    }),
    catchError(err => {
      this._alertService.alertSwal('error', 'A ocurrido un error inesperado, intente mas tarde.')
      return  throwError(err)
    })
  )
}

deleteEmployed(id: number, token: string){
  const headers = { 'Authorization': `Bearer ${token}` }
  return this._http.post<ResponseAddress>(`${ this.URLBackend }/delete_employee`,{id: id},{ headers }).pipe(
    map( (resp: ResponseAddress) => {
      resp.status == true ? this._alertService.alertSwal('success', resp.message) : this._alertService.alertSwal('error', resp.message)
      return resp
    }),
    catchError(err => {
      this._alertService.alertSwal('error', err.error.message)
      return  throwError(err)
    })
  )
}

saveUpdateEmployed(data: any){
  this.up_dataEmploye = data
}

}
